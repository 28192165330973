<template>
  <div>
    <div class="mb-content-header" v-bind:style="{ 'background-color': bgcolor }">
      <div class="mb-content-header-title" v-bind:style="{ color:color, 'font-size': fsize, 'line-height': lineHeight }">
        {{ title }}
      </div>
      <div class="mb-content-header-msg">
        {{ msg }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentHeader",
  props: [ 'title', 'msg', 'color', 'fsize', 'bgcolor', 'lineHeight' ]
}
</script>

<style scoped>
  .mb-content-header {
    min-height: 100px;
    background-color: #F2F9FF;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mb-content-header-title{
    font-family: Lora;
    font-weight: bold;
    font-size: 45px;
    color: #0468B1;
  }

  .mb-content-header-msg{
    font-family: Nunito Semibold;
    font-size: 20px;
    line-height: 24px;
    color: #4B4C4D;
  }

  @media (min-width: 0px) and (max-width: 991.98px) {
    .mb-content-header-msg{
      font-size: 18px;
      line-height: 22px;
    }
  }

  .mb-simple-header .mb-content-header {
    min-height: unset;
  }

</style>